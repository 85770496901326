import { shopConfig } from '../Globals/ShopConfig';

function getTimezone(): string {
  return shopConfig.locale.timezone;
}

function getLocale(): string {
  return shopConfig.locale.locale.replace('_', '-');
}

export function formatDateToLongString(date: string | Date) {
  const timeZone = getTimezone();
  const locale = getLocale();
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: timeZone,
  };
  return new Date(date).toLocaleDateString(locale, options);
}

export function formatDateToLongStringWithTime(date: string | Date) {
  const timeZone = getTimezone();
  const locale = getLocale();
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timeZone,
  };
  return new Date(date).toLocaleDateString(locale, options);
}

export function formatDateToString(
  date: string | Date,
  globalTimeZone = false
): string {
  const locale = getLocale();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  if (!globalTimeZone) {
    options.timeZone = getTimezone();
  }
  return new Date(date).toLocaleDateString(locale, options);
}

export function formatDateToLongMonthString(
  date: string | Date,
  globalTimeZone = false
): string {
  const locale = getLocale();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  if (!globalTimeZone) {
    options.timeZone = getTimezone();
  }
  return new Date(date).toLocaleDateString(locale, options);
}

export function formatDateTimeToString(date: string | Date): string {
  const timeZone = getTimezone();
  const locale = getLocale();
  const options = {
    timeZone: timeZone,
  };
  return new Date(date).toLocaleString(locale, options);
}

export function formatTime(date: string | Date): string {
  const timeZone = getTimezone();
  const locale = getLocale();

  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timeZone,
  };

  return new Date(date).toLocaleTimeString(locale, options);
}

let localDateTimeFormat: Intl.DateTimeFormat = null;
let dateTimeFormat: Intl.DateTimeFormat = null;

function splitLocaleDate(
  date: Date,
  global: boolean // True to convert a date already in the local timezone. False to convert a date in another timezone.
): { month: string; year: string; day: string } {
  let format;
  if (global) {
    if (dateTimeFormat === null) {
      dateTimeFormat = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }
    format = dateTimeFormat;
  } else {
    if (localDateTimeFormat === null) {
      localDateTimeFormat = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: getTimezone(),
      });
    }
    format = localDateTimeFormat;
  }
  const localeDate = format.format(date);
  const parts = localeDate.split('/');
  const year = parts[2];
  const month = ('00' + parts[0]).slice(-2);
  const day = ('00' + parts[1]).slice(-2);

  return {
    year,
    month,
    day,
  };
}

export function formatDateToKey(chosenDate: Date): string {
  const { year, month, day } = splitLocaleDate(chosenDate, false);

  return year + month + day;
}

export function formatToFilterDate(chosenDate: Date): string {
  const { year, month, day } = splitLocaleDate(chosenDate, false);

  return year + '-' + month + '-' + day;
}

export function formatToFilterLocaleDate(chosenDate: Date): string {
  const { year, month, day } = splitLocaleDate(chosenDate, true);

  return year + '-' + month + '-' + day;
}

export function formatToPayloadDate(date: string): string {
  const chosenDate = new Date(date);
  const { year, month, day } = splitLocaleDate(chosenDate, false);

  return day + '-' + month + '-' + year;
}

export function removeTime(date = new Date()): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function getOffset(timeZone = 'UTC', date = new Date()): number {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
}

export function getMidnightDate(date: string | Date): Date {
  const dateAsDate = date instanceof Date ? date : new Date(date + 'T13:00');
  const offset = getOffset(getTimezone(), dateAsDate);
  const sign = offset < 0 ? '-' : '+';
  const offsetHour = Math.floor(Math.abs(offset) / 60);
  const offsetMinute = Math.abs(offset) % 60;
  const dateAsString =
    dateAsDate.getFullYear() +
    '-' +
    ('00' + (dateAsDate.getMonth() + 1)).slice(-2) +
    '-' +
    ('00' + dateAsDate.getDate()).slice(-2);
  return new Date(
    dateAsString +
      'T00:00:00' +
      sign +
      ('00' + offsetHour).slice(-2) +
      ':' +
      ('00' + offsetMinute).slice(-2)
  );
}
