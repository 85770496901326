import {
  barcodeForwardStatus,
  barcodeStatus,
} from '../../../Models/BarcodeModel';
import { Tag } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  ScanOutlined,
  SwapOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import React from 'react';
import Translator from '../../../services/translator';

const getBarcodeStatus = (status: barcodeStatus | barcodeForwardStatus) => {
  switch (status) {
    case 'scanned':
      return (
        <Tag icon={<CheckCircleOutlined />} color="success" bordered={false}>
          {Translator.trans('barcode.status.scanned')}
        </Tag>
      );
    case 'refunded':
      return (
        <Tag
          icon={<ExclamationCircleOutlined />}
          color="processing"
          bordered={false}
        >
          {Translator.trans('barcode.status.refunded')}
        </Tag>
      );
    case 'postponed':
      return (
        <Tag
          icon={<ExclamationCircleOutlined />}
          color="default"
          bordered={false}
        >
          {Translator.trans('barcode.status.postponed')}
        </Tag>
      );
    case 'swapped':
      return (
        <Tag icon={<SwapOutlined />} color="default" bordered={false}>
          {Translator.trans('barcode.status.swapped')}
        </Tag>
      );
    case 'redeemed':
      return (
        <Tag
          icon={<ExclamationCircleOutlined />}
          color="default"
          bordered={false}
        >
          {Translator.trans('barcode.status.redeemed')}
        </Tag>
      );
    case 'giveup':
      return (
        <Tag
          icon={<ExclamationCircleOutlined />}
          color="default"
          bordered={false}
        >
          {Translator.trans('barcode.status.giveup')}
        </Tag>
      );
    case 'opened':
      return (
        <Tag icon={<SyncOutlined spin />} color="processing" bordered={false}>
          {Translator.trans('barcode.forward.status.opened')}
        </Tag>
      );
    case 'canceled':
      return (
        <Tag
          icon={<ExclamationCircleOutlined />}
          color="error"
          bordered={false}
        >
          {Translator.trans('barcode.forward.status.canceled')}
        </Tag>
      );
    case 'closed':
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="default">
          {Translator.trans('barcode.forward.status.closed')}
        </Tag>
      );
    case 'validated':
      return (
        <Tag icon={<ScanOutlined />} color="default" bordered={false}>
          {Translator.trans('barcode.status.validated')}
        </Tag>
      );
    default:
      return null;
  }
};

export default getBarcodeStatus;
