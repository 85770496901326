import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../Title/Title';
import EmisysButton from '../EmisysButton/EmisysButton';
import { postBasketDiscountRequest } from '../../redux/actions/basket.actions';
import { Spin, Skeleton } from 'antd';
import Container from '../Container/Container';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import { checkReduxResponse } from '../../services/httpService';
import AddPromotion from '../AddPromotion/AddPromotion';
import BasketPriceDetail from '../BasketPriceDetail/BasketPriceDetail';
import Translator from '../../services/translator';
import Timer from '../Timer/Timer';
import { storeType } from '../../index';

interface BasketSummaryProps {
  updateBasketReview: () => void;
  goToNextStep: () => void;
}

const BasketSummary = (props: BasketSummaryProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [basketItems, setBasketItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [feeAmount, setFeeAmount] = useState(0);
  const [totalPriceWithoutVat, setTotalPriceWithoutVat] = useState(0);
  const [taxDetails, setTaxDetails] = useState([]);
  const [totalPriceWithVat, setTotalPriceWithVat] = useState(0);

  const currentBasket = useSelector(
    (state: storeType) => state.basket.currentBasket
  );

  const postDiscount = useCallback(
    (discount) => {
      dispatch(postBasketDiscountRequest(discount));
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentBasket) {
      setLoadingItems(currentBasket.loading);
      if (checkReduxResponse(currentBasket, 'basket')) {
        initBasketItems(currentBasket);
        props.updateBasketReview();
        setLoading(false);
      }
    }
  }, [currentBasket, props]);

  const initBasketItems = (currentBasket) => {
    if (currentBasket.basket) {
      setSubTotal(currentBasket.basket.subTotal);
      setFeeAmount(currentBasket.basket.feeAmount);
      setTotalPriceWithoutVat(currentBasket.basket.totalPriceWithoutVat);
      setTaxDetails(currentBasket.basket.taxDetails);
      setTotalPriceWithVat(currentBasket.basket.totalPriceWithVat);
      setBasketItems(
        currentBasket.basket.items ? currentBasket.basket.items : []
      );
    }
  };

  return (
    <>
      <Timer showOnly />
      <Container>
        <Title value={Translator.trans('accounting.detail')} />
        <Skeleton active loading={loading}>
          <Spin spinning={loadingItems}>
            {!loading && (
              <div>
                <AddPromotion
                  onConfirm={(value: string) => postDiscount({ code: value })}
                />

                <BasketPriceDetail
                  subTotal={subTotal}
                  feeAmount={feeAmount}
                  totalPriceWithoutVat={totalPriceWithoutVat}
                  totalPriceWithVat={totalPriceWithVat}
                  taxDetails={taxDetails}
                />

                <EmisysButton
                  disabled={basketItems?.length === 0}
                  onClick={props.goToNextStep}
                >
                  {Translator.trans('order.button.confirm')}
                </EmisysButton>

                <PaymentMethod />
              </div>
            )}
          </Spin>
        </Skeleton>
      </Container>
    </>
  );
};

export default BasketSummary;
