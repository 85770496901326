import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Menu, MenuProps, Spin } from 'antd';
import Timer from '../../Timer/Timer';
import { capitalizeFirstLetter } from '../../../services/capitalizeFirstLetter';
import {
  FileSearchOutlined,
  LoadingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import userDropdown from '../components/userDropdown';
import { getSelfLocaleName } from '../../../services/getLocaleName';
import Translator from '../../../services/translator';
import { CONSTANTSCMS, pagesConstant } from '../../../Constants/Constants';
import { handleChangeLanguage } from '../../../services/parseUrl';
import { useDispatch } from 'react-redux';
import { logUserOut } from '../../../redux/actions/users.actions';
import { removeTokenToStorage } from '../../../services/handleTokens';
import { userProfileType } from '../Header';
import './menu.css';
import { shopConfig } from '../../../Globals/ShopConfig';

interface TopMenuProps {
  isLoading: boolean;
  userProfile: userProfileType;
  isMobile: boolean;
}

const TopMenu = ({ isLoading, userProfile, isMobile }: TopMenuProps) => {
  const dispatch = useDispatch();
  const [dropdownItems, setDropdownItems] = useState([]);
  const currentLang = shopConfig.locale.language;
  const languages = shopConfig.locale.appLanguages;

  const onLogOut = useCallback(() => {
    dispatch(logUserOut());
    removeTokenToStorage();
  }, [dispatch]);

  const changeLanguage = (lang: string) => {
    window.location.href = handleChangeLanguage(
      window.location,
      lang,
      shopConfig.locale
    );
  };

  const items: MenuProps['items'] = [
    {
      key: 'timer',
      className: 'item-menu-header menu-header-item',
      label: <Timer />,
    },
    {
      key: 'tickets',
      className: 'item-menu-header menu-header-item',
      label: userProfile && (
        <Link to={pagesConstant.account.tickets}>
          <div className={'menu-btn'}>
            <FileSearchOutlined />
            <span>{Translator.trans('page.titles.account.tickets')}</span>
          </div>
        </Link>
      ),
    },
    {
      key: 'translations',
      className: 'dropdown-menu-header languages',
      popupClassName: 'dropdown-menu-header-popup',
      popupOffset: [0, 0],
      label: getSelfLocaleName(currentLang),
      children: languages
        .filter((data) => data.lang !== currentLang.toLowerCase())
        .map((data) => ({
          key: data.lang,
          label: (
            <div onClick={() => changeLanguage(data.lang)}>
              <span>{capitalizeFirstLetter(data.language)}</span>
            </div>
          ),
        })),
    },
    {
      key: 'account',
      className: userProfile
        ? 'isLoggedIn dropdown-menu-header account'
        : 'isLoggedOut dropdown-menu-header account',
      popupClassName: 'dropdown-menu-header-popup',
      popupOffset: [0, 0],
      label: !isLoading && (
        <span className="title-my-account">
          {userProfile
            ? userProfile.fullName
            : Translator.trans('account.profile.my_account')}
        </span>
      ),
      icon: isLoading ? (
        <Spin
          indicator={<LoadingOutlined className="header-loading-icon" spin />}
        />
      ) : userProfile?.picture ? (
        <Avatar
          className="avatar-my-account"
          size={30}
          src={userProfile.picture}
        />
      ) : (
        <Avatar
          className="avatar-my-account"
          size={30}
          icon={<UserOutlined />}
        />
      ),
      children: !isLoading ? userDropdown(userProfile, onLogOut) : [],
    },
  ];

  const itemsMobile: MenuProps['items'] = [
    {
      key: 'timer',
      className: 'item-menu-header menu-header-item',
      label: <Timer />,
    },
    {
      key: 'mobile',
      className: userProfile
        ? 'isLoggedIn dropdown-menu-header account'
        : 'isLoggedOut dropdown-menu-header account',
      popupClassName: 'dropdown-menu-header-popup',
      popupOffset: [0, 0],
      icon: isLoading ? (
        <Spin
          indicator={<LoadingOutlined className="header-loading-icon" spin />}
        />
      ) : userProfile ? (
        userProfile.picture ? (
          <Avatar
            className="avatar-my-account"
            size={30}
            src={userProfile.picture}
          />
        ) : (
          <Avatar className="avatar-my-account" size={30}>
            {userProfile.fullName.charAt(0)}
          </Avatar>
        )
      ) : (
        <Avatar
          className="avatar-my-account"
          size={30}
          icon={<UserOutlined />}
        />
      ),
      children: dropdownItems,
    },
  ];

  useEffect(() => {
    let newDropdownItems = [];

    if (!isLoading) {
      newDropdownItems = userDropdown(userProfile, onLogOut);
    }

    newDropdownItems.push({
      type: 'divider',
    });

    languages.forEach((data) =>
      newDropdownItems.push({
        key: data.lang,
        label: (
          <div onClick={() => changeLanguage(data.lang)}>
            <span
              style={{
                fontWeight:
                  data.lang === currentLang.toLowerCase() ? 'bold' : 'normal',
              }}
            >
              {capitalizeFirstLetter(data.language)}
            </span>
          </div>
        ),
      })
    );

    newDropdownItems.push({
      type: 'divider',
    });

    newDropdownItems.push(
      {
        key: 1,
        label: (
          <Link to={pagesConstant.page + CONSTANTSCMS.TERMSURL}>
            {Translator.trans('footer.cgv')}
          </Link>
        ),
      },
      {
        key: 2,
        label: (
          <Link to={pagesConstant.page + CONSTANTSCMS.LEGALURL}>
            {Translator.trans('footer.legal_notice')}
          </Link>
        ),
      },
      {
        key: 3,
        label: (
          <Link to={pagesConstant.page + CONSTANTSCMS.POLICYURL}>
            {Translator.trans('footer.policy')}
          </Link>
        ),
      },
      {
        key: 4,
        label: (
          <Link to={pagesConstant.page + CONSTANTSCMS.INFOSURL}>
            {Translator.trans('footer.faq')}
          </Link>
        ),
      }
    );

    setDropdownItems(newDropdownItems);
  }, [currentLang, isLoading, languages, onLogOut, userProfile]);

  return (
    <Menu
      mode="horizontal"
      selectable={false}
      triggerSubMenuAction={'hover'}
      items={isMobile ? itemsMobile : items}
    />
  );
};

export default TopMenu;
