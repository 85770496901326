import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import EmisysButton from '../EmisysButton/EmisysButton';
import { shopConfig } from '../../Globals/ShopConfig';
import { useSelector } from 'react-redux';
import Translator from '../../services/translator';
import { storeType } from '../../index';
import { checkReduxResponse } from '../../services/httpService';
import './AddPromotion.css';

const AddPromotion = ({ onConfirm }: { onConfirm: (code: string) => void }) => {
  const timeOut = useRef(null);
  const [discountValue, setDiscountValue] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const currentBasket = useSelector(
    (state: storeType) => state.basket.currentBasket
  );

  const hideDiscountMessage = useCallback(() => {
    timeOut.current = setTimeout(() => {
      setIsSuccess(false);
      setErrorMessage('');
    }, 5000);
  }, []);

  useEffect(() => {
    if (checkReduxResponse(currentBasket, 'basket')) {
      if (currentBasket.validDiscount === true) {
        setIsSuccess(true);
      }
      if (currentBasket.validDiscount === false) {
        setErrorMessage(currentBasket.error.message);
      }

      hideDiscountMessage();
    }
  }, [currentBasket, hideDiscountMessage]);

  const handleConfirm = useCallback(() => {
    setIsSuccess(false);
    setErrorMessage('');
    setDiscountValue('');
    onConfirm(discountValue);

    if (timeOut.current !== null) {
      clearTimeout(timeOut.current);
      timeOut.current = null;
    }
  }, [discountValue, onConfirm]);

  return shopConfig.pos.hasDiscount ? (
    <div className="promoCodeContainer">
      <div>
        <div>{Translator.trans('discount.add')}</div>
        <div className="promoInputButtonContainer">
          <div className="inputPromoContainer">
            <Input
              onPressEnter={handleConfirm}
              onChange={(e) => setDiscountValue(e.target.value)}
              value={discountValue}
            />
          </div>
          <EmisysButton
            className="addPromoCodeButton"
            onClick={handleConfirm}
            disabled={discountValue === ''}
          >
            {Translator.trans('word.action.add')}
          </EmisysButton>
        </div>
        {errorMessage && (
          <span className="promoCodeMessageError">{errorMessage}</span>
        )}
        {isSuccess && (
          <span className="promoCodeMessageSuccess">
            {Translator.trans('discount.validation.valid_code')}
          </span>
        )}
      </div>
    </div>
  ) : null;
};

export default AddPromotion;
