import React, { useCallback, useEffect, useState } from 'react';
import { Col, Empty, Row, Spin } from 'antd';
import HeaderShop from '../../components/HeaderShop/HeaderShop';
import { Link } from 'react-router-dom';
import Translator from '../../services/translator';
import { getBarcodesListRequest } from '../../redux/actions/barcodes.actions';
import { useDispatch, useSelector } from 'react-redux';
import { checkReduxResponse } from '../../services/httpService';
import BarcodeModel from '../../Models/BarcodeModel';
import { pagesConstant } from '../../Constants/Constants';
import PictureBanner from '../../components/PictureBanner/PictureBanner';
import { storeType } from '../../index';
import UserBarcodeItem from './UserBarcodeItem/UserBarcodeItem';
import Container from '../../components/Container/Container';
import './index.css';

const UserBarcodesPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentList, setCurrentList] = useState<BarcodeModel[] | null>(null);

  const userBarcodesList = useSelector(
    (state: storeType) => state.barcodes.userBarcodesList
  );

  const getBarcodesList = useCallback(() => {
    dispatch(getBarcodesListRequest());
  }, [dispatch]);

  useEffect(() => {
    getBarcodesList();
  }, [getBarcodesList]);

  useEffect(() => {
    if (userBarcodesList) {
      setIsLoading(userBarcodesList.loading);
      if (checkReduxResponse(userBarcodesList, 'barcodes')) {
        const orderList = userBarcodesList.barcodes.sort(
          (a: BarcodeModel, b: BarcodeModel) => b.id - a.id
        );
        setCurrentList(orderList);
      }
    }
  }, [userBarcodesList]);

  return (
    <Row gutter={16}>
      <Col className="gutter-row" xs={24} sm={24}>
        <PictureBanner />
        <HeaderShop title={Translator.trans('page.titles.account.tickets')}>
          <div className="basket-header-link">
            <Link to={pagesConstant.tickets.home}>
              {Translator.trans('order.button.back_to_products')}
            </Link>
          </div>
        </HeaderShop>
        <Spin spinning={isLoading}>
          {currentList && currentList.length > 0 ? (
            <div className={'barcode-list-container'}>
              {currentList.map((barcode: BarcodeModel) => (
                <UserBarcodeItem
                  key={barcode.id}
                  barcode={barcode}
                  refreshList={getBarcodesList}
                />
              ))}
            </div>
          ) : (
            <Container>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={Translator.trans('order.empty.orders')}
              />
            </Container>
          )}
        </Spin>
      </Col>
    </Row>
  );
};

export default UserBarcodesPage;
